<template>
	<div class="d-flex flex-row">
		<!-- 조회 조건 -->
		<search-condtion :ref="'searchCondtion'" :searchBoxCond="searchBoxCond" :searchOptions="searchOptions"
			@onChange:timeDsvn="changeTimeDsvn" @setDateFormatter="setDateFormatter" />
		<!-- 조회기간 -->
		<date-picker :ref="'datePicker'" :searchBoxCond="searchBoxCond" />
		<!-- 비교대상 -->
		<!-- 
			<compare-combo :ref="'compareCombo'" :searchBoxCond="searchBoxCond" @changeCompareType="changeCompareType" /> 
		-->
	</div>
</template>

<script>
import searchCondtion from "./Type2SearchCondtion.vue";
import datePicker from "./Type2DatePicker.vue";
// import compareCombo from "../CompareCombo.vue";
// import SearchUtils from "@src/views/component/searchBox/analysis/SearchUtils";

export default {
	components: {
		searchCondtion,
		datePicker,
		// compareCombo,
	},
	props: ["searchBoxCond", "searchOptions"],
	watch: {},
	data() {
		return {};
	},
	computed: {},
	created() {
		// let timeDsvn = this.searchBoxCond.conditions.timeDsvn.toLowerCase();
		let timeDsvn = this.searchBoxCond.conditions.timeDsvn;

		if (timeDsvn != "Hourly" || timeDsvn !== "Daily" || timeDsvn !== "Monthly" || timeDsvn !== "Yearly") {
			this.searchBoxCond.conditions.timeDsvn = "Daily";
			this.setDateFormatter();
		}

		let year = new Date().getFullYear();
		let month = new Date().getMonth();
		let day = new Date().getDate();

		if (timeDsvn === "Hourly") {
			// this.searchBoxCond.datePicker.fromDate = new Date(year, month, day);
			this.searchBoxCond.datePicker.toDate = new Date(year, month, day + 1);
		} else if (timeDsvn === "Daily") {
			// this.searchBoxCond.datePicker.fromDate = new Date(year, month, 1);
			this.searchBoxCond.datePicker.toDate = new Date(year, month + 1, 0);
		} else if (timeDsvn === "Monthly") {
			// this.searchBoxCond.datePicker.fromDate = new Date(year, 0, 1);
			this.searchBoxCond.datePicker.toDate = new Date(year, 11, 0);
		} else if (timeDsvn === 'Yearly') {
			// this.searchBoxCond.datePicker.fromDate = new Date(year - 4, 0, 1);
			this.searchBoxCond.datePicker.toDate = new Date(year, 11, 0);
		}

		// fromDate는 Type2Box에서는 의미없으나 오류 가능성을 없애기 위해 fromDate 설정함
		this.searchBoxCond.datePicker.fromDate = this.searchBoxCond.datePicker.toDate;

	},
	mounted() { },
	updated() { },
	destroyed() { },
	methods: {
		checkDateRange() {
			// Type2Box는 toDate만 사용하기 때문에 특별히 유효성 검사가 필요치 않음. 

			// let message = SearchUtils.searchParamValCheck(param);
			// if (message !== "") {
			// 	this.alertNoti(message);
			// 	return false;
			// }

			// console.log(param);
			// let diffInfo = SearchUtils.dateLengthCheck(param.timeDsvn, param.fromDate, param.toDate);

			// message = SearchUtils.wrongDateMessage(param.timeDsvn, diffInfo.diffDay, diffInfo.from);
			// if (message !== "") {
			// 	this.alertNoti(message);
			// 	return false;
			// }

			return true;
		},
		// searchbox 조회 조건 event
		changeTimeDsvn(timeDsvn) {
			console.log(`timeDsvn : ${this.searchBoxCond.conditions.timeDsvn} -> ${timeDsvn}`)

			if (this.searchBoxCond.conditions.timeDsvn !== timeDsvn) {
				this.searchBoxCond.conditions.timeDsvn = timeDsvn;

				// 선택된 날짜가 현재보다 크다면 현재 날자로 변경
				let now = new Date();
				if (this.searchBoxCond.datePicker.toDate > now) {
					this.searchBoxCond.datePicker.toDate = now;
				}

				let year = this.searchBoxCond.datePicker.toDate.getFullYear();

				// Yearly 처리
				if (timeDsvn === "Yearly") {
					this.searchBoxCond.datePicker.fromDate = new Date(year - 9, 0, 1);
				}

				if (timeDsvn === "Hourly") {
					this.searchBoxCond.conditions.compareType = "previousDay";
				} else if (timeDsvn === "Daily") {
					this.searchBoxCond.conditions.compareType = "previousMonth";
				} else if (timeDsvn === "Monthly") {
					this.searchBoxCond.conditions.compareType = "previousYear";
				}

				this.setDateFormatter();
			}
		},
		changeCompareType() {
			this.$refs.datePicker.calcCompareDate();
		},
		setDateFormatter() {
			if (this.$refs.datePicker) {
				this.$refs.datePicker.setDateFormatter();
				this.$refs.datePicker.calcDate(new Date());
			}
		},
	},
};
</script>

<style scoped></style>
