<template>
    <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
        <div class="pt-2 p-l-5">
          <span>{{$t('조회유형')}}</span>
        </div>
        <div class="p-l-5 p-r-5">
          <div class="btn-group">
             <button v-if="searchOptions.includes('Hourly')" :ref="'Hourly'" class="btn btn-white" :class="{ active: searchBoxCond.conditions.timeDsvn === 'Hourly' }" 
                  @click="onClick('Hourly')">{{ $t('일간') }}</button>
              <button v-if="searchOptions.includes('Daily')" :ref="'Daily'" class="btn btn-white" :class="{ active: searchBoxCond.conditions.timeDsvn === 'Daily' }" 
                @click="onClick('Daily')">{{$t('월간')}}</button>
              <button v-if="searchOptions.includes('Monthly')" :ref="'Monthly'" class="btn btn-white" :class="{ active: searchBoxCond.conditions.timeDsvn === 'Monthly' }" 
                @click="onClick('Monthly')">{{$t('연간')}}</button>
              <button v-if="searchOptions.includes('Yearly')" :ref="'Yearly'" class="btn btn-white" :class="{ active: searchBoxCond.conditions.timeDsvn === 'Yearly' }" 
               @click="onClick('Yearly')">{{$t('연도별')}}</button>
            </div>
        </div>
      </div>
</template>

<script>
// import backEndApi from "@api/backEndApi.js";

export default {
  components: {},
  props: ["searchBoxCond", "searchOptions"],
  watch: {},
  data() {
    return {
    };
  },
  computed: {
    
  },
  created() {
    this.searchOptions = this.searchOptions ?? "Hourly,Daily,Monthly,Yearly";
  },
  mounted(){
    this.$emit("onChange:timeDsvn", this.searchBoxCond.conditions.timeDsvn);
  },
  updated(){
  },
  destroyed(){
  },
  methods: {
    // activeTimeDsvnSetting(){
      /*
      switch (this.searchBoxCond.conditions.timeDsvn) {
        case "Month":
          this.searchBoxCond.conditions.compareType = "previousMonth";
          this.$emit("setDateFormatter");
          break;
        case "Year":
          this.searchBoxCond.conditions.compareType = "previousYear";
          this.$emit("setDateFormatter");
          break;
        case "Yearly":
          this.$emit("setDateFormatter");
          break;
        default : 
          this.searchBoxCond.conditions.timeDsvn = "Month";
          this.$emit("setDateFormatter");
          break;
      }  
      */
    // },
    onClick(type) {
      // this.searchBoxCond.conditions.timeDsvn = type;
      let year = this.searchBoxCond.datePicker.toDate.getFullYear();
      // let month = this.searchBoxCond.datePicker.toDate.getMonth();

      if (type == 'Hourly') {
        this.searchBoxCond.datePicker.fromDate = new Date();
        this.searchBoxCond.datePicker.toDate = new Date();
      } else if (type === 'Daily') {
        this.searchBoxCond.datePicker.fromDate = new Date();
        this.searchBoxCond.datePicker.toDate = new Date();
      } else if(type === "Monthly"){ 
        this.searchBoxCond.datePicker.fromDate = new Date(year, 0, 1);
        this.searchBoxCond.datePicker.toDate = new Date(year, 11, 31);
      } 


      this.$emit("onChange:timeDsvn",type);
    }
  },
};
</script>

<style scoped>

</style>