<!-- TODO : 분석모델 정보 포함해야 함 -->

<template>
	<div class="EnergyPrediction col-xl-12">
		<header-box :title="`${$t('에너지 소비현황 분석')} > ${$t('소비량 예측')}`" />

		<div class="row searchArea">
			<div class="col-md-12 innerBox">
				<search-box ref="searchBox" v-if="searchBoxCond" :searchBoxCond="searchBoxCond"
					@submit:data="onSearchComplete" />
			</div>
		</div>

		<card-box v-if="searchResult && searchResult.data.baseData" :options="cardOptions" />

		<chart-box ref="chartBox" v-if="searchResult && searchResult.data.baseData" :searchResult="searchResult" />

		<div class="row gridArea">
			<div class="col-xl-12 col-lg-6 innerBox" v-if="searchResult && searchResult.data.baseData">
				<base-grid-box ref="gridBox" :options="baseGridOptions" />
			</div>
		</div>
	</div>
</template>

<script>
import "element-ui-table/dist/element-table.css";

import backEndApi from "@api/backEndApi";
import AnalysisLib from "../AnalysisLib.js";

import HeaderBox from "../../component/headerBox/analysis/Header.vue";
import SearchBox from "./EnergyPredictionSearchBox.vue";

import CardBox from "../LightAnalysisCards.vue";
import ChartBox from "./EnergyPredictionChart.vue";
// import ChartBox from 
import BaseGridBox from "../LightAnalysisDataGrid.vue";

import { StatusCodes } from "http-status-codes";
import AppErrorHandler from "@src/appErrorHandler";
import * as popupMessages from "@src/consts/popupMessageConsts";
// import energyPredictionSearchBox from "./EnergyPredictionSearchBox.vue";
// import energyPredictionChart from "./EnergyPredictionChart.vue";
// import energyPredictionGridBox from "./EnergyPredictionGridBox.vue";
// import EnergyPredictionCard from "./EnergyPredictionCard.vue";

export default {
	name: "EnergyPrediction",
	props: ["searchBoxCond", "viewType"],

	components: {
		HeaderBox,
		SearchBox,
		CardBox,
		ChartBox,
		BaseGridBox,

		// energyPredictionSearchBox,
		// energyPredictionChart,
		// energyPredictionGridBox,
		// EnergyPredictionCard,
	},
	data() {
		return {
			searchCond: null,
			baseGridOptions: null,
			debug: true,
			cardOptions: [],
			searchResult: null,
			popupMessages
		};
	},
	mounted() {
		this.searchBoxCond.debug = this.debug;
		this.searchBoxCond.viewType = this.viewType;

		this.debug && console.warn(`### LightAnalysis:${this.viewType} started`);
	},

	methods: {
		onSearchComplete(searchResult) {
			// console.log(data);
			this.searchResult = searchResult;
			this.searchResult.debug = this.debug;

			this.searchResult = AnalysisLib.makeEnergyPredictionAnalysisData(searchResult);
			this.debug && console.log("AnalysisData: ", this.searchResult);

			if (this.searchResult) {
				this.initCardBox(this.searchResult);
				this.initGridBox(this.searchResult);
			}
		},

		initCardBox(searchResult) {
			// 초기화
			try {
				this.cardOptions = [];

				if (!searchResult) {
					console.warn("searchResult is Empty");
					return;
				}

				let summary = searchResult.data.summary;
				summary.forEach((sumItem) => {
					let option = {
						color: AnalysisLib.getCardColor(this.viewType, sumItem.divCd),
						unit: sumItem.unit,
						compType: searchResult.options.conditions.compareType,
						icon: "",
						divCd: sumItem.divCd,
						title: sumItem.divCdName,
						baseVal: sumItem.baseVal,
						compVal: sumItem.compareVal,
						diffRate: sumItem.differenceRate,
					};

					this.cardOptions.push(option);
				});

				this.debug && console.log("cardOptions: ", this.cardOptions);
			} catch (e) {
				console.error("OrgUnit Error: ", e);
			}
		},

		initGridBox(searchResult) {
			try {
				this.baseGridOptions = null;

				this.baseGridOptions = {};

				if (searchResult.data.baseData.length > 0) {
					this.baseGridOptions.debug = this.debug;
					this.baseGridOptions.header = searchResult.data.header;
					this.baseGridOptions.data = searchResult.data.baseData;
					this.baseGridOptions.dateType = searchResult.options.conditions.timeDsvn;

					this.baseGridOptions.download = AnalysisLib.getDownloadFileName("소비량예측", "base", this.searchBoxCond);

					this.debug && console.log("download filename(base):", this.baseGridOptions.download);
				}
			} catch (e) {
				console.error("OrgUnit Error: ", e);
			}
		},

		async onSearch(param) {
			this.searchCond = param;

			if (param.timeDsvn === "Hourly") {
				this.timeInfo = "일간";
			} else if (param.timeDsvn === "Monthly") {
				this.timeInfo = "월간";
			}

			//let result = await backEndApi.cstnPredic.basic(param);

			// // result.data.header[]
			// //    {divCd, divCdName, charVisiable, gridVisible, unit}
			// // result.data.summary[]
			// //    {divCd, divCdName, unit, baseVal}
			// // result.data.baseData[]
			// //    {chartRegDtTag, Pred, PredRate, Total, ...}

			// if (!result.data.errno) {
			// 	this.predicData = result.data;
			// } else if (result.data.errno === 107) {
			// 	this.predicData = {
			// 		header: [],
			// 		baseData: [],
			// 		summary: [],
			// 	};
			// } else {
			// 	this.alertNoti(result.data.message);
			// }

			/*           
						↓↓↓↓↓
			*/

			try {
				let result = await backEndApi.cstnPredic.basic(param);
				this.predicData = result.data;

			} catch (err) {

				new AppErrorHandler(err)
					.setOtherMessage(this.popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
					.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
					.createBizHandle(this.ERR_KIND.MISSING_DATA, this.popupMessages.COMMON_ANALYSIS_NO_DATA_POPUP_MESSAGE, this.alertNoti)
					.errHandling(true);
			}
		},
	},
};
</script>

<style lang="scss">
.el-table .kbet-el-table th {
	background-color: #f5f7fa;
}

.el-table th,
.el-table tr {
	background-color: #f5f7fa;
}

.comparisonPage {
	background-color: brown;
}

.searchArea {
	margin: 8px 0 8px 0;
	background-color: #00000010;
	border-radius: 8px;
}

.cardArea,
.chartArea,
.graphArea,
.gridArea {
	margin: 8px 0 8px 0;
}

.innerBox {
	padding: 5px;
}

.dateSelect {
	background-color: cornflowerblue;
	height: 200px;
}

.timeSelect {
	background-color: cyan;
	height: 200px;
}

.cardView {
	background-color: darkkhaki;
	height: 200px;
}

.mixChart {
	background-color: #ffffff;
	height: 300px;
}

.drawLine {
	height: 300px;
}</style>
