<template>
	<div class="row p-10">
		<!-- 구역 구분 -->
		<!-- <zone-combo-box :ref="'zoneComboBox'" @ready:data="onInitComplete"></zone-combo-box> -->
		<!-- 에너지원 -->
		<!-- <energy-source-code-combo-box :ref="'energySourceCodeComboBox'" :enableTotal="true" @ready:data="onInitComplete" /> -->
		<!-- 조회조건, 조회기간, 비교대상 -->
		<!-- <search-condition-type-box :ref="'searchConditionBox'" :searchBoxCond="searchBoxCond" /> -->
		<!-- 조회버튼 -->
		<!-- <search-btn :ref="'searchBtn'" @search="onSearch" /> -->

		<!-- 에너지원 -->
		<!-- <energy-source-code-combo-box :ref="'energySourceCodeComboBox'" :enableTotal="false" @ready:data="onInitComplete">
			<template v-slot:customOption>
				<option value="">전체</option>
			</template>
		</energy-source-code-combo-box> -->

		<energy-predic-code-combo-box :ref="'energyPredicCodeComboBox'" :enableTotal="true" :energySourceCode="this.energySourceCode" @ready:data="onInitComplete">
			<!-- <template v-slot:customOption>
				<option value="">전체</option>
			</template> -->
		</energy-predic-code-combo-box>

		<!-- 조회조건, 조회기간, 비교대상 -->
		<search-condition-type-box :ref="'searchConditionTypeBox'" :searchBoxCond="searchBoxCond" :searchOptions="'Hourly,Daily'" />
		<!-- 조회버튼 -->
		<search-btn :ref="'searchBtn'" @search="onSearch" />
	</div>
</template>

<script>
	import backEndApi from "@src/api/backEndApi";

	// import energySourceCodeComboBox from "@src/views/component/comboBox/management/EnergySourceCodeComboBox.vue";
	import energyPredicCodeComboBox from "../../component/comboBox/analysis/EnergyPredicCodeComboBox.vue";
	import searchConditionTypeBox from "../../component/searchBox/analysis/type2/Type2Box.vue";
	import searchBtn from "../../component/searchBox/analysis/SearchBtn.vue";

	import { StatusCodes } from "http-status-codes";
	import AppErrorHandler from "@src/appErrorHandler";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		name: "IndoorEnvironmentSearchBox",
		props: ["originSearch", "searchBoxCond"],
		components: {
			// energySourceCodeComboBox,
			energyPredicCodeComboBox,
			searchConditionTypeBox,
			searchBtn,
		},
		data() {
			return {
				selectPtAddr: null,
				waitLoads: 0,
				popupMessages,
			};
		},
		async created() {},
		async mounted() {
			this.waitLoads = 1;
		},
		methods: {
			async onInitComplete(data) {
				this.searchBoxCond.debug && console.debug(`onInitComplete() Initial data loading start.(data:${data}, waits:${--this.waitLoads})`);
				if (this.waitLoads <= 0) await this.onSearch();
			},
			async onSearch() {
				try {
					let searchResult = {
						options: this.searchBoxCond,
						data: [],
					};

					let param = null;
					param = {
						timeDsvn: this.searchBoxCond.conditions.timeDsvn,
						// fromDate: this.searchBoxCond.datePicker.fromDate.format(this.searchBoxCond.datePicker.paramFromFormatter),
						// toDate: this.searchBoxCond.datePicker.toDate.format(this.searchBoxCond.datePicker.paramToFormatter).substring(0,6) + '31',
						toDate: this.searchBoxCond.datePicker.toDate.format(this.searchBoxCond.datePicker.paramToFormatter),
						energySourceCode: "",
						// energySourceCode: this.$refs.energySourceCodeComboBox.getValue(),
					};
					console.log("Search Params(base): ", param);

					// if (!this.$refs.searchConditionTypeBox.checkDateRange(param)) {
					// 	return;
					// }

					// backEndApi.cstnPredic.basic(param).then((result) => {
					// 	if (!result.data.errno) {
					// 		searchResult = {
					// 			options: this.searchBoxCond,
					// 			data: result.data,
					// 		};

					// 		this.$emit("submit:data", searchResult);
					// 	} else if (result.data.errno) {
					// 		this.alertNoti(result.data.message);
					// 	} else {
					// 		throw Object.assign({ api: "backEndApi.cstnPredic.basic(param)" }, result.data);
					// 	}
					// });

					/*           
							↓↓↓↓↓
				*/

					try {
						const result = await backEndApi.cstnPredic.basic(param);

						searchResult = {
							options: this.searchBoxCond,
							data: result.data,
						};

						this.$emit("submit:data", searchResult);
					} catch (err) {
						new AppErrorHandler(err)
							.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
							.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
							.createBizHandle(this.ERR_KIND.MISSING_DATA, popupMessages.COMMON_ANALYSIS_NO_DATA_POPUP_MESSAGE, this.alertNoti)
							.errHandling(true);
					}
				} catch (e) {
					console.error("UseTargetSearch Error : ", e);
				}
			},

			loadOptions({ callback }) {
				callback();
			},
		},
	};
</script>

<style lang="scss">
	.IndoorEnvironmentSearchBox {
		.SearchLabel {
			align-self: center;
			text-align: center;
			font-size: 16px;
			font-weight: bold;
		}

		.compare {
			margin-top: -5px;
			margin-bottom: 0px;
		}

		.material-switch > input[type="checkbox"] {
			display: none;
		}

		.material-switch > label {
			cursor: pointer;
			height: 0px;
			position: relative;
			width: 40px;
		}

		.material-switch > label::before {
			background: rgb(0, 0, 0);
			box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
			border-radius: 8px;
			content: "";
			height: 16px;
			margin-top: -8px;
			position: absolute;
			opacity: 0.3;
			transition: all 0.4s ease-in-out;
			width: 40px;
		}
		.material-switch > label::after {
			background: rgb(255, 255, 255);
			border-radius: 16px;
			box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
			content: "";
			height: 24px;
			left: -4px;
			margin-top: -8px;
			position: absolute;
			top: -4px;
			transition: all 0.3s ease-in-out;
			width: 24px;
		}
		.material-switch > input[type="checkbox"]:checked + label::before {
			background: inherit;
			opacity: 0.5;
		}
		.material-switch > input[type="checkbox"]:checked + label::after {
			background: inherit;
			left: 20px;
		}
	}
</style>
