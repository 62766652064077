<template>
	<div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px' }">
		<div class="pt-2 p-l-5">
			<span>{{ $t('조회기간') }}</span>
		</div>
		<div class="p-l-5">
			<div class="d-flex flex-row">
				<div>
					<date-picker placeholder="Start Date" :hidden="searchBoxCond.conditions.timeDsvn !== 'Yearly'"
						:value="searchBoxCond.datePicker.fromDate"
						:style="{ width: '100px' }"
						:config="{ format: this.fromFormat, viewMode: this.searchBoxCond.datePicker.viewMode, locale: locale, ignoreReadonly: true }"
						@dp-hide="onFromDatePicker($event.target.value)" />
				</div>
				<div class="pt-2 p-l-5 p-r-5">
					<span v-if="searchBoxCond.conditions.timeDsvn === 'Yearly'"> ~ </span>
				</div>
				<div class="p-r-5">
					<date-picker placeholder="End Date" readonly="readonly" :value="searchBoxCond.datePicker.toDate"
						:style=" { width: '100px' }"
						:config="{ format: this.toFormat, viewMode: this.searchBoxCond.datePicker.viewMode, locale: locale, ignoreReadonly: true }"
						@dp-hide="onToDatePicker($event.target.value)" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import SearchUtils from "../SearchUtils";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
	name: "Type2DatePicker",
	components: {},
	props: ["searchBoxCond"],
	watch: {},
	data() {
		return {
			config: {},
			popupMessages,
		};
	},
	computed: {
		locale() {
			return this.$store.state.locale === 'kr' ? 'ko' : this.$store.state.locale;
		},

		fromFormat: {
			get: function () {
				return this.searchBoxCond.datePicker.fromFormatter;
			},
			set: function (newValue) {
				this.searchBoxCond.datePicker.fromFormatter = newValue;
			},
		},
		toFormat: {
			get: function () {
				return this.searchBoxCond.datePicker.toFormatter;
			},
			set: function (newValue) {
				this.searchBoxCond.datePicker.toFormatter = newValue;
			},
		},
	},
	created() { },
	mounted() { },
	destroyed() { },

	methods: {
		calcDate(timeDsvn, date) {
			let result = null;

			if (!date) date = new Date();

			switch (timeDsvn) {
				case "Hourly":
					result = new Date(date.getFullYear(), date.getMonth(), date.getDate());
					break;
				case "Daily":
					result = new Date(date.getFullYear(), date.getMonth() + 1, 0);
					break;
				case "Monthly":
					result = new Date(date.getFullYear(), date.getMonth(), 1);
					break;
				case "Yearly":
					result = new Date(date.getFullYear(), 0, 1);
					break;
				default:
					result = new Date(date.getFullYear(), date.getMonth(), date.getDate());
					break;
			}

			return result;
		},

		onFromDatePicker(value) {
			let timeDsvn = this.searchBoxCond.conditions.timeDsvn;

			let date = new Date(value);
			let now = new Date();

			date = (date > now) ? now : date;

			if (timeDsvn === 'Daily') {
				this.searchBoxCond.datePicker.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
			} else if (timeDsvn === 'Monthly') {
				this.searchBoxCond.datePicker.fromDate = new Date(date.getFullYear(), 0, 1);
			} else if (timeDsvn === 'Yearly') {
				this.searchBoxCond.datePicker.fromDate = new Date(date.getFullYear(), 0, 1);

				// fromDate > toDate인 경우, fromDate를 동일하게 설정해야 함.
				if (this.searchBoxCond.datePicker.fromDate > this.searchBoxCond.datePicker.toDate) {
					this.searchBoxCond.datePicker.toDate = this.searchBoxCond.datePicker.fromDate;
				}
			}

			// TYPE-2에서는 데이터 범위 체크 불필요.
			// let result = SearchUtils.dateLengthCheck(
			// 	timeDsvn,
			// 	this.searchBoxCond.datePicker.fromDate.format("yyyy-MM-dd"),
			// 	this.searchBoxCond.datePicker.toDate.format("yyyy-MM-dd")
			// );

			// if (result.diff < 0) {
			// 	this.alertNoti("조회기간을 잘못설정하셨습니다.").then(() => {
			// 		this.searchBoxCond.datePicker.fromDate = this.searchBoxCond.datePicker.toDate;
			// 	});

			// 	return;
			// }

			// let message = SearchUtils.wrongDateMessage(timeDsvn, result.diffDay);

			// if (message !== "") {
			// 	this.alertNoti(message);
			// 	return;
			// }

			// this.searchBoxCond.datePicker.fromDate = this.calcDate(timeDsvn, date);
		},

		onToDatePicker(value) {
			//@dp-change로 이벤트 동작 시 무한 루프
			// let date = new Date(this.searchBoxCond.datePicker.fromDate);
			let timeDsvn = this.searchBoxCond.conditions.timeDsvn;

			let date = new Date(value);
			let now = new Date();

			date = (date > now) ? now : date;

			// this.searchBoxCond.datePicker.toDate = this.calcDate(this.searchBoxCond.conditions.timeDsv, date);

			if (timeDsvn === 'Daily') {
				this.searchBoxCond.datePicker.toDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
			} else if (timeDsvn === 'Monthly') {
				this.searchBoxCond.datePicker.toDate = new Date(date.getFullYear(), 11, 31);
			} else if (timeDsvn === 'Yearly') {
				this.searchBoxCond.datePicker.toDate = new Date(date.getFullYear(), 11, 31);

				if (this.searchBoxCond.datePicker.fromDate > this.searchBoxCond.datePicker.toDate) {
					this.searchBoxCond.datePicker.fromDate = this.searchBoxCond.datePicker.toDate;
				}
			}

			// TYPE-2에서는 데이터 범위 체크 불필요.
			// let result = SearchUtils.dateLengthCheck(
			// 	timeDsvn,
			// 	this.searchBoxCond.datePicker.fromDate.format("yyyy-MM-dd"),
			// 	this.searchBoxCond.datePicker.toDate.format("yyyy-MM-dd")
			// );

			// if (result.diff < 0) {
			// 	this.alertNoti("조회기간을 잘못설정하셨습니다.").then(() => {
			// 		this.searchBoxCond.datePicker.fromDate = this.searchBoxCond.datePicker.toDate;
			// 	});

			// 	return;
			// }

			// let message = SearchUtils.wrongDateMessage(timeDsvn, result.diffDay);

			// if (message !== "") {
			// 	this.alertNoti(message);
			// 	return;
			// }

			// if (timeDsvn === "Month") {

			// 	let year = this.searchBoxCond.datePicker.toDate.getFullYear();
			// 	let month = this.searchBoxCond.datePicker.toDate.getMonth();
			// 	this.searchBoxCond.datePicker.fromDate = new Date(year, month, 1);
			// 	this.searchBoxCond.datePicker.toDate = new Date(year, month + 1, 0);

			// 	// this.searchBoxCond.datePicker.fromDate = this.calcDate(this.searchBoxCond.conditions.timeDsv, date);
			// }

			// if (timeDsvn === 'Year') {
			// 	let year = this.searchBoxCond.datePicker.toDate.getFullYear();
			// 	// let month = this.searchBoxCond.datePicker.toDate.getMonth();
			// 	this.searchBoxCond.datePicker.fromDate = new Date(year, 0, 1);
			// 	this.searchBoxCond.datePicker.toDate = new Date(year, 11, 31);
			// }
		},

		setDateFormatter() {
			switch (this.searchBoxCond.conditions.timeDsvn) {
				case "Daily":
					this.fromFormat = "YYYY-MM";
					this.toFormat = "YYYY-MM";

					this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMMdd";
					this.searchBoxCond.datePicker.paramToFormatter = "yyyyMMdd";
					break;
				case "Monthly":
					this.fromFormat = "YYYY";
					this.toFormat = "YYYY";

					this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMM";
					this.searchBoxCond.datePicker.paramToFormatter = "yyyyMM";
					break;
				case "Yearly":
					this.fromFormat = "YYYY";
					this.toFormat = "YYYY";

					this.searchBoxCond.datePicker.paramFromFormatter = "yyyy";
					this.searchBoxCond.datePicker.paramToFormatter = "yyyy";
					break;
				default:
					this.fromFormat = "YYYY-MM-DD";
					this.toFormat = "YYYY-MM-DD";

					this.searchBoxCond.datePicker.paramFromFormatter = "yyyyMMdd";
					this.searchBoxCond.datePicker.paramToFormatter = "yyyyMMdd";
					break;
			}
		},
	},
};
</script>

<style scoped>
.form-control[readonly] {
	background: #fff;
	color: #2d353c;
	opacity: 1;
}
</style>
